import { datadogRum } from '@datadog/browser-rum';
import { AppFooter } from '@webstore-monorepo/features/app-footer';
import { useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { PlatformStoreProvider } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useGetFeatureFlag } from '@webstore-monorepo/shared/hooks/use-get-feature-flag';
import { getDiffInSeconds } from '@webstore-monorepo/shared/utils/cart';
import { ErrorFallback } from '@webstore-monorepo/ui/error-fallback';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { CountryCode } from 'react-native-country-picker-modal';
import { Outlet } from 'react-router';

import { Header } from '../components/header';
import { handleOpenLinkInRN } from '../gp-react-native';
import { useAppContextState } from '../shared/contexts/app-context-provider';
import { useNotification } from '../shared/contexts/notification-provider';
import { useAnalytics } from '../shared/hooks/use-analytics';
import { useIsNewCheckoutFlow } from '../shared/hooks/use-is-new-checkout-flow';
import { useViewportHeight } from '../shared/utils/hooks/useViewportHeight';
import { getFormattedMessage, getIntlNumber, getIntlString } from '../shared/utils/intl';

const App: React.FC = () => {
  const analytics = useAnalytics();
  const notification = useNotification();
  const appContext = useAppContextState();
  const { header } = useComponentsConfig();
  const appState = useAppContextState();
  const { selectedMenuConceptUniqueNames } = appState;
  const { isFetched } = useWebStoreItems({ selectedMenuConceptUniqueNames });
  useViewportHeight();
  const store = useStoreState();
  const headerHeight = appContext?.headerElementHeight ?? 0;
  const [top, setTop] = React.useState<number>(0);
  const isNewCheckoutFlow = useIsNewCheckoutFlow();
  const isGuestCheckoutEnabled = useGetFeatureFlag('isGuestCheckoutEnabled');

  const container = document.getElementById('placeholder-container');
  if (container && container.parentNode && isFetched) {
    container?.parentNode.removeChild(container);
  }

  useEffect(() => {
    if (headerHeight) {
      setTop((prev) => (headerHeight === 0 ? prev : headerHeight));
    }
  }, [headerHeight, top]);

  useEffect(() => {
    analytics.track('page_load', {
      loadTime: getDiffInSeconds(new Date(), window.__GP_START_PAGE_LOAD),
    });
    // DD custom action to understand funnels in RUM Monitoring
    datadogRum.addAction('page_load');
    datadogRum.addFeatureFlagEvaluation('new_checkout_flow', isNewCheckoutFlow);
    datadogRum.addFeatureFlagEvaluation('guest_checkout', isGuestCheckoutEnabled);
  }, []);

  const handleSocialLinkClick = (url: string) => {
    handleOpenLinkInRN(url, true);
  };

  return (
    <PlatformStoreProvider
      defaultErrorMessage={getIntlString('general.error')}
      defaultCountryCode={((store?.country?.defaultCode ?? 'US').toUpperCase() as CountryCode) ?? 'US'}
      notification={notification}
      analytics={analytics}
      intl={{ getIntlString: getIntlString, formatMessage: getFormattedMessage, formatNumber: getIntlNumber }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Header />
      </ErrorBoundary>
      <main role="main">
        <div id="content" className="site-content category-menu" style={{ paddingTop: top, minHeight: 'calc(100vh - 91px)' }}>
          <Outlet />
        </div>
      </main>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <footer>
          <AppFooter onSocialLinkClick={handleSocialLinkClick} />
        </footer>
      </ErrorBoundary>
    </PlatformStoreProvider>
  );
};

export default App;
